import React from 'react';
// export const Nav00DataSource = {
//   wrapper: { className: 'header0 home-page-wrapper' },
//   page: { className: 'home-page' },
//   logo: {
//     className: 'header0-logo',
//     children: 'https://os.alipayobjects.com/rmsportal/mlcYmsRilwraoAe.svg',
//   },
//   Menu: {
//     className: 'header0-menu',
//     children: [
//       {
//         name: 'item0',
//         className: 'header0-item',
//         children: {
//           href: '#',
//           children: [{ children: '导航一', name: 'text' }],
//         },
//         subItem: [
//           {
//             name: 'sub0',
//             className: 'item-sub',
//             children: {
//               className: 'item-sub-item',
//               children: [
//                 {
//                   name: 'image0',
//                   className: 'item-image',
//                   children:
//                     'https://gw.alipayobjects.com/zos/rmsportal/ruHbkzzMKShUpDYMEmHM.svg',
//                 },
//                 {
//                   name: 'title',
//                   className: 'item-title',
//                   children: 'Ant Design',
//                 },
//                 {
//                   name: 'content',
//                   className: 'item-content',
//                   children: '企业级 UI 设计体系',
//                 },
//               ],
//             },
//           },
//           {
//             name: 'sub1',
//             className: 'item-sub',
//             children: {
//               className: 'item-sub-item',
//               children: [
//                 {
//                   name: 'image0',
//                   className: 'item-image',
//                   children:
//                     'https://gw.alipayobjects.com/zos/rmsportal/ruHbkzzMKShUpDYMEmHM.svg',
//                 },
//                 {
//                   name: 'title',
//                   className: 'item-title',
//                   children: 'Ant Design',
//                 },
//                 {
//                   name: 'content',
//                   className: 'item-content',
//                   children: '企业级 UI 设计体系',
//                 },
//               ],
//             },
//           },
//         ],
//       },
//       {
//         name: 'item1',
//         className: 'header0-item',
//         children: {
//           href: '#',
//           children: [{ children: '导航二', name: 'text' }],
//         },
//       },
//       {
//         name: 'item2',
//         className: 'header0-item',
//         children: {
//           href: '#',
//           children: [{ children: '导航三', name: 'text' }],
//         },
//       },
//       {
//         name: 'item3',
//         className: 'header0-item',
//         children: {
//           href: '#',
//           children: [{ children: '导航四', name: 'text' }],
//         },
//       },
//     ],
//   },
//   mobileMenu: { className: 'header0-mobile-menu' },
// };
export const Banner50DataSource = {
  wrapper: { className: 'home-page-wrapper banner5' },
  page: { className: 'home-page banner5-page k9b1oqkfcs-editor_css' },
  childWrapper: {
    className: 'banner5-title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>印有为</p>
          </span>
        ),
        className: 'banner5-title',
      },
      {
        name: 'explain',
        className: 'banner5-explain',
        children: (
          <span>
            <span>
              <p>一个高效的智能云打印解决平台</p>
            </span>
          </span>
        ),
      },
      {
        name: 'content',
        className: 'banner5-content',
        children: (
          <span>
            <p>支持饿了么等外卖平台，断网自动连接、自动补单。</p>
          </span>
        ),
      },
      {
        name: 'button',
        className: 'banner5-button-wrapper',
        children: {
          href: 'https://admin.yinyouwei.com/admin/login.html',
          className: 'banner5-button',
          type: 'primary',
          children: '开始使用',
          target: '_blank',
        },
      },
    ],
  },
  image: {
    className: 'banner5-image',
    children:
      'https://gw.alipayobjects.com/mdn/rms_ae7ad9/afts/img/A*-wAhRYnWQscAAAAAAAAAAABkARQnAQ',
  },
};
export const Feature70DataSource = {
  wrapper: {
    className: 'home-page-wrapper feature7-wrapper k9c3rg41meu-editor_css',
  },
  page: { className: 'home-page feature7' },
  OverPack: { playScale: 0.3, className: 'k9b66ukjr2-editor_css' },
  titleWrapper: {
    className: 'feature7-title-wrapper',
    children: [
      {
        name: 'title',
        className: 'feature7-title-h1',
        children: (
          <span>
            <p>产品优点</p>
          </span>
        ),
      },
      {
        name: 'content',
        className: 'feature7-title-content',
        children: (
          <span>
            <p>优势和特点</p>
          </span>
        ),
      },
    ],
  },
  blockWrapper: {
    className: 'feature7-block-wrapper',
    gutter: 30,
    children: [
      {
        md: 12,
        xs: 24,
        name: 'block0',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group',
          children: [
            {
              name: 'image',
              className: 'feature7-block-image',
              children:
                'https://gw.alipayobjects.com/zos/basement_prod/e339fc34-b022-4cde-9607-675ca9e05231.svg',
            },
            {
              name: 'title',
              className: 'feature7-block-title',
              children: (
                <span>
                  <p>高扩展性、高兼容性</p>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'feature7-block-content',
              children: (
                <span>
                  <p>
                    用户可以直接通过API接入高稳定、高扩展能力、先进管理平台工具的云计算中心。
                  </p>
                </span>
              ),
            },
          ],
        },
      },
      {
        md: 12,
        xs: 24,
        name: 'block1',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group',
          children: [
            {
              name: 'image',
              className: 'feature7-block-image',
              children:
                'https://gw.alipayobjects.com/zos/basement_prod/e339fc34-b022-4cde-9607-675ca9e05231.svg',
            },
            {
              name: 'title',
              className: 'feature7-block-title',
              children: (
                <span>
                  <span>
                    <p>数据传输稳定、迅捷</p>
                  </span>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'feature7-block-content',
              children: (
                <span>
                  <span>
                    <span>
                      <p>
                        用户接入云平台就可以拥有能够弹性扩展的计算资源，享受稳定的云打印服务。
                      </p>
                    </span>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        md: 12,
        xs: 24,
        name: 'block2',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group k9b66itq1do-editor_css',
          children: [
            {
              name: 'image',
              className: 'feature7-block-image',
              children:
                'https://gw.alipayobjects.com/zos/basement_prod/e339fc34-b022-4cde-9607-675ca9e05231.svg',
            },
            {
              name: 'title',
              className: 'feature7-block-title',
              children: (
                <span>
                  <p>数据安全加密</p>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'feature7-block-content',
              children: (
                <span>
                  <span>
                    <p>
                      后台服务器端和Web服务采用多层次的数据安全防御设计，保护客户信息。
                    </p>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        md: 12,
        xs: 24,
        name: 'block3',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group',
          children: [
            {
              name: 'image',
              className: 'feature7-block-image',
              children:
                'https://gw.alipayobjects.com/zos/basement_prod/e339fc34-b022-4cde-9607-675ca9e05231.svg',
            },
            {
              name: 'title',
              className: 'feature7-block-title',
              children: (
                <span>
                  <span>
                    <p>安全、高性能、高可靠</p>
                  </span>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'feature7-block-content',
              children: (
                <span>
                  <span>
                    <span>
                      <p>
                        满足关键业务对<span>
                          高可靠、高性能、高安全的严苛诉求。
                        </span>
                      </p>
                    </span>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
    ],
  },
};
export const Feature80DataSource = {
  wrapper: { className: 'home-page-wrapper feature8-wrapper' },
  page: { className: 'home-page feature8' },
  OverPack: { playScale: 0.3 },
  titleWrapper: {
    className: 'feature8-title-wrapper',
    children: [
      { name: 'title', className: 'feature8-title-h1', children: '使用流程' },
      {
        name: 'content',
        className: 'feature8-title-content',
        children: '流程简单清晰，快速响应需求',
      },
    ],
  },
  childWrapper: {
    className: 'feature8-button-wrapper',
    children: [
      {
        name: 'button',
        className: 'feature8-button',
        children: {
          href: 'https://admin.yinyouwei.com/admin/login.html',
          children: '立即体验',
          target: '_blank',
        },
      },
    ],
  },
  Carousel: {
    dots: false,
    className: 'feature8-carousel',
    wrapper: { className: 'feature8-block-wrapper' },
    children: {
      className: 'feature8-block',
      titleWrapper: {
        className: 'feature8-carousel-title-wrapper',
        title: { className: 'feature8-carousel-title' },
      },
      children: [
        {
          name: 'block0',
          className: 'feature8-block-row',
          gutter: 120,
          title: {
            className: 'feature8-carousel-title-block',
            children: '平台自主训练流程',
          },
          children: [
            {
              className: 'feature8-block-col',
              md: 6,
              xs: 24,
              name: 'child0',
              arrow: {
                className: 'feature8-block-arrow',
                children:
                  'https://gw.alipayobjects.com/zos/basement_prod/167bee48-fbc0-436a-ba9e-c116b4044293.svg',
              },
              children: {
                className: 'feature8-block-child',
                children: [
                  {
                    name: 'image',
                    className: 'feature8-block-image',
                    children:
                      'https://gw.alipayobjects.com/zos/basement_prod/d8933673-1463-438a-ac43-1a8f193ebf34.svg',
                  },
                  {
                    name: 'title',
                    className: 'feature8-block-title',
                    children: (
                      <span>
                        <span>
                          <p>注册店铺</p>
                        </span>
                      </span>
                    ),
                  },
                  {
                    name: 'content',
                    className: 'feature8-block-content',
                    children: (
                      <span>
                        <span>
                          <p>第一步，把饿了么店铺和印有为平台进行授权绑定</p>
                        </span>
                      </span>
                    ),
                  },
                ],
              },
            },
            {
              className: 'feature8-block-col',
              md: 6,
              xs: 24,
              name: 'child1',
              arrow: {
                className: 'feature8-block-arrow',
                children:
                  'https://gw.alipayobjects.com/zos/basement_prod/167bee48-fbc0-436a-ba9e-c116b4044293.svg',
              },
              children: {
                className: 'feature8-block-child',
                children: [
                  {
                    name: 'image',
                    className: 'feature8-block-image',
                    children:
                      'https://gw.alipayobjects.com/zos/basement_prod/d8933673-1463-438a-ac43-1a8f193ebf34.svg',
                  },
                  {
                    name: 'title',
                    className: 'feature8-block-title',
                    children: (
                      <span>
                        <span>
                          <span>
                            <span>
                              <p>注册打印机</p>
                            </span>
                          </span>
                        </span>
                      </span>
                    ),
                  },
                  {
                    name: 'content',
                    className: 'feature8-block-content',
                    children: (
                      <span>
                        <span>
                          <p>第二步，将我公司现有产品注册到云打印平台</p>
                        </span>
                      </span>
                    ),
                  },
                ],
              },
            },
            {
              className: 'feature8-block-col',
              md: 6,
              xs: 24,
              name: 'child2',
              arrow: {
                className: 'feature8-block-arrow',
                children:
                  'https://gw.alipayobjects.com/zos/basement_prod/167bee48-fbc0-436a-ba9e-c116b4044293.svg',
              },
              children: {
                className: 'feature8-block-child',
                children: [
                  {
                    name: 'image',
                    className: 'feature8-block-image',
                    children:
                      'https://gw.alipayobjects.com/zos/basement_prod/d8933673-1463-438a-ac43-1a8f193ebf34.svg',
                  },
                  {
                    name: 'title',
                    className: 'feature8-block-title',
                    children: (
                      <span>
                        <span>
                          <span>
                            <p>分配店铺</p>
                          </span>
                        </span>
                      </span>
                    ),
                  },
                  {
                    name: 'content',
                    className: 'feature8-block-content',
                    children: (
                      <span>
                        <p>
                          第三步，选择已绑定的店铺进行分配，一台打印机可分配多个店铺
                        </p>
                      </span>
                    ),
                  },
                ],
              },
            },
            {
              className: 'feature8-block-col',
              md: 6,
              xs: 24,
              name: 'child3',
              arrow: {
                className: 'feature8-block-arrow',
                children:
                  'https://gw.alipayobjects.com/zos/basement_prod/167bee48-fbc0-436a-ba9e-c116b4044293.svg',
              },
              children: {
                className: 'feature8-block-child',
                children: [
                  {
                    name: 'image',
                    className: 'feature8-block-image',
                    children:
                      'https://gw.alipayobjects.com/zos/basement_prod/d8933673-1463-438a-ac43-1a8f193ebf34.svg',
                  },
                  {
                    name: 'title',
                    className: 'feature8-block-title',
                    children: (
                      <span>
                        <p>测试打印机</p>
                      </span>
                    ),
                  },
                  {
                    name: 'content',
                    className: 'feature8-block-content',
                    children: (
                      <span>
                        <p>第四步，测试打印机是否连接，完成之后可以正式使用</p>
                      </span>
                    ),
                  },
                ],
              },
            },
          ],
        },
      ],
    },
  },
};
// export const Footer00DataSource = {
//   wrapper: { className: 'home-page-wrapper footer0-wrapper' },
//   OverPack: { className: 'home-page footer0', playScale: 0.05 },
//   copyright: {
//     className: 'copyright',
//     children: (
//       <span>
//         ©2018 <a href="https://motion.ant.design">Ant Motion</a> All Rights
//         Reserved
//       </span>
//     ),
//   },
// };
